.list--header {
  display: flex;
  margin: 0.5rem 0;
  justify-content: space-between;
}

.list--header h2 {
  color: #526d82;
}

.list--header select {
  border-radius: 10px;
  border: none;
  padding: 8px 12px;
  border: 1px solid #dde6ed;
  color: #526d82;
}

.map--container {
  border: none;
  border-radius: 20px;
  flex: 1;
  color: black;
  min-height: 300px;
  min-width: 464px;
  margin-bottom: 20px;
}

.list--container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.list {
  display: flex;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
}

.device--detail {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #526d82;
}

.device--detail img {
  width: 50px;
  background: #dde6ed;
  border-radius: 50%;
}

.list:hover {
  background: #dde6ed;
  border-radius: 10px;
}
