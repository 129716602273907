* {
  margin: 0;
  padding: 0;
}

.container {
  overflow-x: auto;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

table {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.204);
  border-radius: 10px;
  margin: 20px auto;
  border-collapse: collapse;
  background-color: #fff;
}

th {
  padding: 1rem;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-bottom: 2px solid #000;
}
th:hover {
  background-color: #778da9;
  transition: 0.3s ease-in-out;
}

th:first-of-type {
  border-top-left-radius: 10px;
}

th:last-of-type {
  border-top-right-radius: 10px;
}

tr:hover {
  background-color: rgb(232, 232, 232, 0.64);
}

td {
  padding: 1rem;
}

.btn-container {
  margin: auto;
  max-width: 25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.btn-container span {
  color: #393d3f;
  padding: 0 1rem;
}

.btn-container button {
  border: 1px solid #dedbd2;
  padding: 0.5rem 1rem;
  background-color: black;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.btn-container button:hover {
  background-color: #175676;
}

.btn-container button:disabled {
  cursor: not-allowed;
  background-color: rgb(229, 229, 229);
  color: rgba(0, 0, 0, 0.269);
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  max-width: 60rem;
  margin: 3rem auto;
  padding: 2rem;
  background: radial-gradient(#051923, #0d1321);
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
}

.export-button {
  border: 1px solid #0d1b2a;
  padding: 2px 12px;
  background-color: white;
  color: black;
  cursor: pointer;
}
.export-button:hover {
  background-color: #cce6f4;
  transition: 0.3s ease-in-out;
}

.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.date-picker-container label {
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
}

.select-device {
  padding: 2px 2px;
  margin-right: 10px;
}

.clear-button {
  cursor: pointer;
  padding: 2px 12px;
  background-color: #fff;
  border: none;
}

.detail-coordinate {
  border-color: #0d1b2a;
  padding: 0.2rem;
  color: #fff;
  background-color: #2b2d42;
  cursor: pointer;
}

.detail-coordinate:hover {
  background-color: #edf2f4;
  color: #000;
}

.heatmap-modal-button {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  background-color: #9d0208;
  color: #fff;
}

.heatmap-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  background-color: #eaf4f4;
  border-radius: 0.5rem;
}

.heatmap-modal-button:hover {
  background-color: #780000;
}

.reports-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #01161e;
  min-height: 10vh;
  padding-top: 1rem;
  border-top: 1px solid #000;
}
.footer-p {
  color: #fff;
  font-weight: bold;
}

.color-scale {
  margin-top: 20px;
}

.color-scale h3 {
  margin-bottom: 10px;
}

.color-scale-container {
  display: flex;
}

.color-box {
  width: 30px;
  height: 30px;

  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  /* Ekran genişliği 768px'e düştüğünde bu kurallar geçerli olacak */

  .controls-container {
    flex-direction: column;
    margin-bottom: 3rem;
    padding: 1rem;
  }

  .date-picker-container {
    flex-direction: column;
  }

  .container {
    padding: 1rem;
  }

  .btn-container {
    flex-direction: column;
    align-items: center;
  }
}
