.anomaly-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.anomaly-hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.anomaly-hero-content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.anomaly-left-section,
.anomaly-right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.anomaly-left-section {
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  gap: 20px;
}
.anomaly-left-section-title {
  font-size: 30px;
  font-weight: bold;
  color: #f3f7ec;
}

.anomaly-button {
  border-radius: 8px;
  background-color: #eeeeee;
  border: 1px solid #f1f1f1;
  justify-content: center;
  align-self: center;
  padding: 8px 16px;
  cursor: pointer;
  color: #254336;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}
.anomaly-button:hover {
  transition: 0.2s ease-in-out;
  color: #c73659;
}

.anomaly-right-section {
  border-left: 2px solid rgba(255, 255, 255, 0.2);
}

input[type="file"] {
  display: block;
  margin: 20px auto;
}

button:disabled {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.anomaly-file-info {
  margin-top: 10px;
}

.anomaly-progress-bar-container {
  background-color: #021526;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.anomaly-progress-bar {
  background-color: #eeeeee;
  height: 10px;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.anomaly-progress-bar.uploading {
  background-color: #ffc107; /* Sarı renk */
}

.anomaly-progress-bar.success {
  background-color: #28a745; /* Yeşil renk */
}

.anomaly-progress-bar.error {
  background-color: #dc3545; /* Kırmızı renk */
}

.anomaly-success-message {
  color: #28a745;
  margin-top: 10px;
}
