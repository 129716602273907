.menu {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 2.3rem;
  height: 94vh;
}

.logo {
  text-align: center;
  padding: 10px;
  color: #dde6ed;
  align-items: center;
  display: flex;
  gap: 20px;
}

.logo-icon {
  font-size: 1.4rem;
}

.menu--list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menu--list .item {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  color: #dde6ed;
}

.menu--list .item:hover,
.active {
  background: #27374d;
  color: #dde6ed !important;
}
