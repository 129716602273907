.content {
  width: 75%;
}

.content--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header--title {
  color: #526d82;
}

.header--activity {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-box {
  background: #dde6ed;
  padding: 8px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.search-box input {
  border: none;
  outline: none;
  background: transparent;
  padding: 5px;
}

.search-box .icon {
  color: #969393;
  font-size: 22px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.notify {
  background: #dde6ed;
  padding: 12px;
  border-radius: 10px;
  color: #969393;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notify .icon {
  font-size: 20px;
  transition: 0.5s ease-in-out;
}

.icon:hover {
  transform: scale(1.3);
  cursor: pointer;
}

/* card container */
.card--container {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 2rem;
}

.card {
  flex: 1;
  background: #dde6ed;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  transition: 0.5s ease-in-out;
}

.card:hover {
  transform: scale(1.04);
  cursor: pointer;
}

.card--cover {
  background: #fff;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  color: #415a77;
}

.card--title {
  background: #ffffffc0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #bf0603;
  border-radius: 10px;

  /* Gölge Efekti */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease; /* Opsiyonel: Gölge efekti için geçiş efekti */
}

.card--desc {
  color: #127475;
  font-weight: 700;
  font-size: 16px;
}

.card--title:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}
