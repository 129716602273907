.project-heading {
  text-align: center;
  padding: 4rem 0 2rem 0;
}

.project-container {
  max-width: 1440px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 6rem;
}

.project-card {
  background: #1a1919;
  padding: 1.2rem 1rem;
}

.project-card img {
  width: 100%;
}

.project-title {
  color: #fff;
  padding: 1rem 0;
}

.pro-details p {
  padding-bottom: 1rem;
  font-size: 1.1rem;
  text-align: justify;
}

.pro-btns {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  gap: 10px;
}

.work-but {
  display: block;
  padding: 12px;
  font-size: 1rem;
  width: 80%;
  margin: 2rem auto;
  background: #edf2f4;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.work-but:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

@media screen and (max-width: 740px) {
  .project-container {
    max-width: 90%;
    margin: auto;

    grid-template-columns: 1fr;
  }
}
