.anomaly-results {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 32px;
  border-radius: 10px;
  color: #fff;
  margin: 20px;
  width: 100%;
  max-width: 800px;
}

.anomaly-results h2 {
  margin-bottom: 10px;
  text-align: center;
}
.anomaly-section {
  margin-bottom: 30px;
}
.anomaly-info {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.anomaly-tablist {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.anomaly-results p {
  margin-bottom: 4px;
}

.map-container {
  display: flex;
  flex-direction: column;
}
.anomaly-tablist {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-button {
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  color: #f1f1f1;
  background: linear-gradient(45deg, #170435, #c80036);
  padding: 5px 10px;
  transition: background 0.3s, transform 0.3s;
}

/* .chart-button:hover {
  background: linear-gradient(45deg, #c80036, #170435);
  transform: scale(1.01);
} */
