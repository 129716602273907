.profile {
  flex: 1;
}

.profile--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit {
  background: #dde6ed;
  padding: 12px;
  border-radius: 10px;
  color: #969393;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit .icon {
  font-size: 20px;
  transition: 0.5s ease-in-out;
}

.user--profile {
  height: 85%;
  margin-top: 2rem;
  background: #dde6ed;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.user--detail {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user--detail img {
  background: #fff;
  border-radius: 50%;
  width: 150px;
  margin-bottom: 10px;
}

.user--detail .username {
  color: #026d82;
  margin-top: 0.5rem;
}

.device-config {
  background: #fff;
  flex: 1;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.config {
  background: #dde6ed;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.config-detail {
  display: flex;
  gap: 20px;
  align-items: center;
}

.config-cover {
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
}

.config .attribute {
  font-size: 12px;
}

.config .action {
  font-weight: 900;
  font-size: 1.2rem;
}

.profession {
  color: #969393;
}
