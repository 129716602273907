.new-state-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
  width: 66.666667%;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 6rem 0;
  border-radius: 1rem;
}

.state-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.state-button {
  color: #000;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.state-button:hover {
  color: #adb5bd;
}

.state-save-button {
  background-color: #343a40;
  padding: 0.5rem 1.5rem;
  color: #fff;
  border-radius: 0.375rem;
  margin-right: 1rem;
  cursor: pointer;
}

.state-save-button:hover {
  background-color: #212529;
}

.input-group {
  margin-top: 2rem;
}

.input-container {
  display: flex;
  height: auto;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.input-style {
  width: 100%;
  padding: 0.25rem;
  border-radius: 0.325rem;

  background-color: #ced4da;
  color: #343a40;
  border: none;
  margin-top: 0.1rem;
}

.input-style:focus {
  border-color: #495057;
  border: 1px solid #212529;
}

.label-style {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: #343a40;
}

.modal-header {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-paragraph {
  color: #000;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .new-state-container {
    width: 58.333333%;
  }
}
