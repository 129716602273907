.state-sidebar-container {
  text-align: center;
  width: 33.333333%;
  min-width: 14rem;
  min-height: 100vh;
  border-top-right-radius: 0.75rem;
  background-color: #2b2d42;
  margin-top: 2rem;
  padding: 4rem 2rem;
}

.state-title {
  margin-bottom: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 1rem;
  line-height: 1rem;
}

.h4-title {
  margin-top: 2rem;
  font-weight: bold;
  text-align: center;
  color: #000;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.5rem 2rem;
  border: none;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  border-radius: 0.375rem;
  background-color: #fff;
}

.state-add-button {
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.5rem 4.2rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  background: #fff;
  color: #000;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.state-add-button:hover {
  background-color: #cad2c5;
}

.data-button {
  width: 100%;
  text-align: left;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  color: #fff;
  border: none;
  font-size: 1rem;
  background-color: transparent;
  cursor: pointer;
}

.data-button-special {
  width: 100%;
  text-align: left;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  color: #001233;
  border: none;
  font-size: 1rem;
  background-color: transparent;
  cursor: pointer;
  background-color: brown;
  color: cornflowerblue;
}

.data-button:hover {
  color: #979dac;
}

.current-states {
  color: #fff;
  padding: 0.5rem 0;
  text-align: center;
}

@media (min-width: 768px) {
  .state-sidebar-container {
    width: 18rem;
  }

  .state-title {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .state-add-button {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .h4-title {
    font-size: 1.125rem;
  }
}
