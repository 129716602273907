.unauth-container {
  width: 100%;
  max-height: 100vh;
  position: absolute;
  top: 20vh;
  margin: 1rem auto;
  text-align: center;
  padding: 2rem 0;
}

.unauth-title {
  margin-bottom: 1rem;
}

.unauth-paragraph {
  margin-bottom: 2rem;
}

.unauth-icon {
  width: 12rem;
  height: 12rem;
  color: #780000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.unauth-button {
  border: none;
  background-color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.unauth-button:hover {
  background-color: #780000;
  color: #fff;
  transition: 0.3s ease-in-out;
}
