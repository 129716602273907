.pricing {
  width: 100%;
  padding: 6rem 1rem;
  background: #000;
}

.summary-card-container {
  max-width: 1440px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.summary-card {
  border: 1px solid #eee;
  color: #eee;
  text-align: center;
  padding: 1rem;
}

.summary-card:hover {
  background-color: #141414;
}

.summary-card h3 {
  font-size: 1.5rem;
  padding: 1rem;
}

.summary-card p {
  padding: 10px 0;
}

.bar {
  border-bottom: 1px solid #eee;
  width: 10%;
  margin: 1.5rem auto; /* Üst ve alt dan 1.5rem boşluk bırakırken, sol ve sağ kenarlarda otomatik (merkezi hizalanmış) bir boşluk bırakır */
  display: block; /* Elementin bir blok olarak görüntüler yani elementler dikey olarak birbirini takip eder */
}

.card-title {
  font-size: 4rem;
  font-weight: 600;
  padding: 1rem 0;
}

.sum-btn {
  display: block;
  padding: 12px;
  font-size: 1rem;
  width: 50%;
  margin: 2rem auto;
  background: #edf2f4;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.sum-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

@media screen and (max-width: 740px) {
  .summary-card-container {
    max-width: 90%;
    margin: auto;

    grid-template-columns: 1fr;
  }
}
