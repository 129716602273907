.hero {
  height: 100%;
  width: 100%;
}

.hero .content {
  position: absolute;
  transform: translate(
    -50%,
    -50%
  ); /* yatay ve dikey öteleme işlemini ifade eder. Bu kod, merkezi konumda konumlandırır. */
  top: 50%;
  left: 50%;
  text-align: center;
}

.hero .content h1 {
  font-size: 4rem;
  padding: 0.6rem 0 1.5rem;
}

.hero .content p {
  font-size: 1.2rem;
  font-weight: 200;
  text-transform: uppercase;
}

.content .btn {
  margin: 1rem 0.2rem;
}

.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.into-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

/* after seçicisi, öğrenin içerisine eklenen içerik öğresini temsil eder. */
/* Bu Css kodu, bir öğrenin içerisine siyah bir arka plan ekleyerek arka plan karartma işlevini gerçekleştirir. */
.mask::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.6;
}

@media screen and (max-width: 640px) {
  .hero .content h1 {
    font-size: 3rem;
  }

  .hero .content p {
    font-size: 1.2rem;
  }
}
