.selected-date-container {
  width: 50%;
  margin-top: 16rem;
  background-color: #f8f9fa;
  max-height: 50vh;
  border-radius: 1rem;
}

.selected-date-header {
  border-bottom: 2px;
  border-color: #000;
  padding: 1rem 1rem;
}

.selected-date-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.selected-buttton-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.selected-title {
  font-weight: bold;
  font-size: 2rem;
  color: #343a40;
}

.selected-delete-button {
  color: #e5e5e5;
  border-radius: 0.2rem;
  padding: 8px 16px;
  cursor: pointer;
  background-color: #d90429;
  border: none;
}

.selected-cancel-button {
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 8px;
  border: none;
  background-color: #343a40;
  color: #fff;
  border-radius: 0.2rem;
}

.selected-delete-button:hover {
  background-color: #ef233c;
}

.selected-cancel-button:hover {
  background-color: #000;
}

.selected-parag {
  color: 1rem;
  color: #c1121f;
  font-weight: bold;
  margin-bottom: 10rem;
}

.selected-parag-2 {
  color: #000;
}

.dest-group {
  color: #000;
}
