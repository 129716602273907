.dialog-style {
  background-color: #f8f9fa;
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-style {
  text-align: right;
}

.dialog-button-style {
  background-color: #212529;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #e9ecef;
  border-radius: 0.375rem;
  cursor: pointer;
}

.dialog-button-style:hover {
  background-color: #343a40;
}
