.container-group {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.no-state-container {
  margin: 2rem 1rem;
  text-align: center;
  width: 95%;
  background-color: #2b2d42;
  height: auto;
  border-radius: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.main-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all-state-container {
  margin: 0.5rem 1rem;
  text-align: center;
  min-width: 50%;
  background-color: #fff;
  height: 60vh;
  padding-top: 1rem;
  overflow: auto;
  border-radius: 0.5rem;
}

.main-filter-container {
  display: flex;
  flex-direction: column;
  width: 44%;
  background-color: #2b2d42;
  height: 60vh;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
}

.filter-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}

.filter-container-title {
  padding-top: 0.5rem;
}

.filter-input-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  flex-direction: row;
}

.filter-button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  color: #000;
  background-color: #fff;
}
.filter-button:hover {
  background-color: #eaeaea;
}

.filter-input {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid #000;
  background-color: #fff;
}

.state-parag {
  color: #022b3a;
  padding-top: 0.2rem;
}

.no-state-img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  margin: 2rem;
}

.no-state-selected-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.no-state-selected-parag {
  color: #adb5bd;
  margin-bottom: 1rem;
}

.create-parag {
  margin-top: 2rem;
}

.create-state-button {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  border: none;
}
.create-state-button:hover {
  color: #370617;
  background-color: #cad2c5;
}

.card-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
}

.sub-card-container {
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #0d1b2a;
  cursor: pointer;
}
.sub-card-container:hover {
  background-color: #8d99ae;
}

.cardStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.p-view-start {
  font-size: 14px;
  color: #f8f9fa;
  align-self: flex-start;
}
.p-view-end {
  font-size: 12px;
  color: #f8f9fa;
  align-self: flex-end;
}

.main-card-title {
  color: #edede9;
  font-weight: bold;
  font-size: 2rem;
  padding: 0.2rem 1rem;
}

@media (min-width: 768px) {
  .create-state-button {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
