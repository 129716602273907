/* Badge.css */

.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}

.admin {
  background-color: #9d0208;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
}

/* Diğer tipler için isteğe bağlı stiller ekleyebilirsiniz */
.warning {
  background-color: #f39c12;
  color: #fff;
  border: 2px solid #d68910;
}

.success {
  background-color: #2ecc71;
  color: #fff;
  border: 2px solid #27ae60;
}
