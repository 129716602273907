@import "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@400;900&display=swap");

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: #000;
  margin: 0;
}

h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}

li {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #edf2f4;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

.dashboard {
  padding: 20px;
  display: flex;
  gap: 20px;
}

.dashboard-content {
  background: #fff;
  flex: 1;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}

.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background: linear-gradient(300deg, #ccc9dc, #0c1821, #000000);
  animation: gradient-animation 10s ease infinite;
  background-size: 180% 180%;
  justify-content: center;
  align-items: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  width: 400px;
  height: auto;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

form {
  width: 100%;
  margin-bottom: 50px;
  padding: 10px;
}

form .form-group {
  margin-bottom: 10px;
  width: 100%;
}

.form-container form input {
  display: block;
  padding: 10px;
  width: 100%;
  border: 1px solid black;
  font-size: 16px;
  color: black;
}

.form-container form button {
  padding: 10px 25px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease-in-out;
}

.form-container form button:hover {
  background-color: #778da9;
  color: #fff;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.errmsg {
  background-color: #fff;
  color: #d00000;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.daily-state-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

/* Advertise CSS  */

/* Page Settings */
.adv-page-settings {
  overflow-x: hidden;
  color: #d1d5db; /* 'text-neutral-300' Tailwind renginin hex değeri */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh; /* Sayfanın tamamını kapsar */
  background: radial-gradient(
    ellipse 80% 80% at 0% 100%,
    rgba(13, 101, 183, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-color: #000;
}

.adv-fixed-background {
  position: fixed;
  top: 0;
  z-index: -10;
  height: 100%;
  width: 100%;
}

.adv-absolute-background {
  position: absolute;
  top: 0;
  z-index: -2;
  height: 100vh;
  width: 100vw;
}

.adv-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem; /* 'px-8' padding-left 8 Tailwind birimi (2rem) */
  padding-right: 2rem; /* 'px-8' padding-right 8 Tailwind birimi (2rem) */
}

/* Hero Section */
.element {
  border-bottom: 1px solid #171717; /* 'border-neutral-900' Tailwind renginin hex değeri */
  padding-bottom: 8rem;
  padding-top: 4rem; /* 'pb-4' padding-bottom 4 Tailwind birimi (1rem) */
}

.hero-container {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1024px) {
  /* lg breakpoint Tailwind'de 1024px ve üzeridir */
  .hero-container {
    flex-wrap: nowrap;
    align-items: center;
  }
}

.full-width {
  width: 100%;
}

@media (min-width: 1024px) {
  /* lg breakpoint Tailwind'de 1024px ve üzeridir */
  .half-width {
    width: 50%;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1024px) {
  /* lg breakpoint Tailwind'de 1024px ve üzeridir */
  .lg-items-start {
    align-items: flex-start;
  }
}

.hero-header {
  font-size: 3.5rem; /* 'text-6xl' Tailwind birimi 4rem */
  font-weight: 100; /* 'font-thin' Tailwind ağırlığı */
  letter-spacing: -0.01562em; /* 'tracking-tight' Tailwind birimi */
}

@media (min-width: 1024px) {
  /* lg breakpoint Tailwind'de 1024px ve üzeridir */
  .hero-header {
    margin-top: 4rem; /* 'lg:mt-16' Tailwind birimi 4rem */
    font-size: 3.5rem; /* 'lg:text-8xl' Tailwind birimi 6rem */
  }
}

.gradient-text {
  background: linear-gradient(
    to right,
    #979797,
    #3f6ca4,
    #094db4
  ); /* 'from-pink-300' (#fcb6c1), 'via-slate-500' (#64748b), 'to-purple-500' (#7e22ce) Tailwind renk değerleri */
  -webkit-background-clip: text; /* 'bg-clip-text' için */
  background-clip: text;
  color: transparent; /* 'text-transparent' için */
  font-size: 2.25rem; /* 'text-4xl' Tailwind birimi 2.25rem */
  letter-spacing: -0.01562em; /* 'tracking-tight' Tailwind birimi */
}

.hero-content {
  margin-top: 0.5rem; /* 'my-2' Tailwind birimi 0.5rem */
  margin-bottom: 0.5rem; /* 'my-2' Tailwind birimi 0.5rem */
  max-width: 36rem; /* 'max-w-xl' Tailwind birimi 36rem */
  padding-top: 1.5rem; /* 'py-6' Tailwind birimi 1.5rem */
  padding-bottom: 1.5rem; /* 'py-6' Tailwind birimi 1.5rem */
  font-weight: 300; /* 'font-light' Tailwind ağırlığı */
  letter-spacing: -0.02em; /* 'tracking-tighter' Tailwind birimi */
}

.hero-image-width {
  width: 100%;
}

@media (min-width: 1024px) {
  /* lg breakpoint Tailwind'de 1024px ve üzeridir */
  .hero-image-width {
    width: 50%;
  }
  .hero-image-padding {
    padding: 2rem; /* 'lg:p-8' Tailwind birimi 2rem */
  }
}

.hero-image-flex-center {
  display: flex;
  justify-content: center;
}

.profile-video {
  width: 100%;
  height: auto;
  border-radius: 10px; /* İsteğe bağlı: video köşelerini yuvarlatır */
}

/* About */
.about-border {
  border-bottom: 1px solid #1f2937; /* Corresponds to border-neutral-900 */
  padding-bottom: 4rem; /* Corresponds to pb-4 */
}

.about-header {
  margin: 5rem 0; /* Corresponds to my-20 */
  text-align: center; /* Corresponds to text-center */
  font-size: 2.25rem; /* Corresponds to text-4xl */
}

.about-text {
  color: #6b7280; /* Corresponds to text-neutral-500 */
}

.about-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1024px) {
  /* lg breakpoint Tailwind'de 1024px ve üzeridir */
  .about-flex-wrap {
    flex-wrap: nowrap;
  }
}

.about-column-1,
.about-column-2 {
  width: 100%; /* Corresponds to w-full */
}

@media (min-width: 1024px) {
  /* Corresponds to lg: */
  .about-column-1 {
    width: 50%; /* Corresponds to lg:w-1/2 */
    padding: 2rem; /* Corresponds to lg:p-8 */
  }
  .about-column-2 {
    width: 50%; /* Corresponds to lg:w-1/2 */
  }
}

.about-content,
.about-justify-content {
  display: flex;
  justify-content: center;
  align-items: center; /* Corresponds to items-center */
}

@media (min-width: 1024px) {
  /* Corresponds to lg: */
  .about-justify-content {
    justify-content: flex-start; /* Corresponds to lg:justify-start */
  }
}

.about-image {
  border-radius: 1rem;
}

.about-content-style {
  margin: 0.5rem 0; /* Corresponds to my-2 */
  max-width: 36rem; /* Corresponds to max-w-xl */
  padding: 1.5rem 0; /* Corresponds to py-6 */
}

/* Features */
.adv-features-border {
  border-bottom: 1px solid #2d3748; /* Corresponds to border-neutral-800 */
  padding-bottom: 6rem; /* Corresponds to pb-24 */
}

.adv-features-header-title {
  margin: 5rem 0; /* Corresponds to my-20 */
  text-align: center; /* Corresponds to text-center */
  font-size: 2.25rem; /* Corresponds to text-4xl */
}

.adv-features-flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Corresponds to items-center */
  justify-content: center; /* Corresponds to justify-center */
  gap: 3rem; /* Corresponds to gap-4 */
}

.adv-features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.adv-features-rounded-border {
  border-radius: 1rem; /* Corresponds to rounded-2xl */
  border: 4px solid #2d3748; /* Corresponds to border-4 and border-neutral-800 */
  padding: 1rem; /* Corresponds to p-4 */
}

.adv-velocity-icon-style {
  font-size: 3rem; /* Corresponds to text-7xl */
  color: #c20f0f; /* Corresponds to text-cyan-400 */
}

.adv-altitude-icon-style {
  font-size: 3rem; /* Corresponds to text-7xl */
  color: #532c04; /* Corresponds to text-cyan-400 */
}

.adv-vibration-icon-style {
  font-size: 3rem; /* Corresponds to text-7xl */
  color: #d3c41e; /* Corresponds to text-cyan-400 */
}

.adv-buffer-icon-style {
  font-size: 3rem; /* Corresponds to text-7xl */
  color: #f4f4f4; /* Corresponds to text-cyan-400 */
}

.adv-icon-description {
  margin-top: 1rem;
  font-size: 1rem;
  color: #fff;
  justify-content: center;
}

/* Partners */
.adv-partners-border {
  border-bottom: 1px solid #1f2937; /* border-neutral-900 */
  padding-bottom: 1rem; /* pb-4 */
}

.adv-partners-header-title {
  margin: 5rem 0; /* my-20 */
  text-align: center; /* text-center */
  font-size: 2.25rem; /* text-4xl */
}

.adv-partners-item {
  margin-bottom: 2rem; /* mb-8 */
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* lg:justify-center */
}

.adv-partners-image-container {
  width: 100%; /* w-full */
}

@media (min-width: 1024px) {
  .adv-partners-image-container {
    width: 25%; /* lg:w-1/4 */
  }
}

.adv-partners-image {
  margin-bottom: 1.5rem; /* mb-6 */
  border-radius: 0.5rem; /* rounded */
}

.adv-partners-details {
  width: 100%; /* w-full */
  max-width: 36rem; /* max-w-xl */
}

@media (min-width: 1024px) {
  .adv-partners-details {
    width: 75%; /* lg:w-3/4 */
  }
}

.adv-partners-title {
  margin-bottom: 0.5rem; /* mb-2 */
  font-weight: 600; /* font-semibold */
}

.adv-partners-description {
  margin-bottom: 1rem; /* mb-4 */
  color: #9ca3af; /* text-neutral-400 */
}

.adv-tech-tag {
  margin-right: 0.5rem; /* mr-2 */
  border-radius: 0.25rem; /* rounded */
  background-color: #1f2937; /* bg-neutral-900 */
  padding: 0.25rem 0.5rem; /* Add padding for better appearance */
  color: white; /* Ensure text color is readable */
}

/* Contact */
.adv-contact-border {
  border-bottom: 1px solid #1f2937; /* border-neutral-900 */
  padding-bottom: 5rem; /* pb-20 */
}

.adv-contact-header-title {
  margin: 2.5rem 0; /* my-10 */
  text-align: center; /* text-center */
  font-size: 2.25rem; /* text-4xl */
}

.adv-contact-info {
  text-align: center; /* text-center */
  letter-spacing: -0.05em; /* tracking-tighter */
}

.adv-contact-details {
  margin: 1rem 0; /* my-4 */
}

/* Landing Page CSS */
.landing-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  color: #fff;
  width: 100%;
  padding: 0px 60px;
  position: relative;
  overflow: hidden;
}

.landing-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%; /* Ensure content does not overflow */
  height: 80%;
  margin-top: 30px;
}

.landing-info-landing {
  width: 50%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 60px;
}

@keyframes dropDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.landing-info-landing h1 {
  font-size: 70px;
  font-weight: bold;
  animation: dropDown 1s ease-in-out;
}

.landing-typewriter {
  margin-top: 5px;
  font-size: 45px;
  color: red;
  font-weight: bold;
  animation: minusHorizontalDrop 1s ease-in-out;
}

@keyframes plusHorizontalDrop {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes minusHorizontalDrop {
  0% {
    transform: translateX(-30%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.swiper {
  width: 50%;
  height: 70%;
  border-radius: 50px;
  user-select: none;
  animation: plusHorizontalDrop 1s ease;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  opacity: 0.92;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure particles are in the background */
}

.landing-scrollText {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

@keyframes dropUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.landing-scrollDownBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: dropUp 1s ease-in-out;
}

.landing-arrows {
  background-color: #fff;
  opacity: 0.7;
  border-radius: 25px;
  width: 40px;
  height: 62px;
  margin-bottom: 10px;
  transition: 0.4s ease;
  cursor: pointer;
}

.landing-arrows:hover {
  background-color: red;
  transform: translateY(5px);
}

.landing-arrows path {
  stroke: #000;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.landing-arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}

.landing-arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}

.landing-arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

@media screen and (max-width: 1250px) {
  .landing-info-landing {
    padding-left: 0px;
  }
}

@media screen and (max-width: 1100px) {
  .landing-content {
    flex-direction: column;
  }

  .landing-info-landing {
    width: 80%;
    margin-bottom: 20px;
  }

  .landing-info-landing h1 {
    font-size: 50px;
  }

  .landing-typewriter {
    font-size: 40px;
  }

  .swiper {
    width: 80%;
    height: 40%;
    margin: 10px 0px;
  }
}

@media screen and (max-width: 750px) {
  .landing-app {
    padding: 0 20px;
  }

  .landing-info-landing h1 {
    font-size: 35px;
  }

  .landing-typewriter {
    font-size: 25px;
  }
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

.landing-logo img {
  position: absolute;
  top: 30px;
  left: 100px;
  padding-left: 20px;
  height: 8%;
  width: auto;
  animation: dropDown 1s ease-in-out;
}
